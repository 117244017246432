import {
  WebAppProvider,
  MainButton,
  BackButton,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";
import "./App.css";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Grid2,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";

const locations = ["Toshkent Shahar", "Samarqand Shahar", "Jom"];
const getChannels = [];
const sendChannels = ["-4592051798"];

function App() {
  const WebApp = useWebApp();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [isPhoneVisible, setPhoneVisible] = useState(false);
  const [isPeopleNumberVisible, setPeopleNumberVisible] = useState(false);
  const [phone, setPhone] = useState("");
  const [peopleNumber, setPeopleNumber] = useState(1);

  const onSubmitForm = () => {
    const url = `https://api.telegram.org/bot7332726771:AAE_3W2E-LWt0kn7Bapno08uYiE_jgexPJY/sendMessage`;

    var message = `<b>🚘 Taxi kerak</b>\n\n<b>Qayerdan:</b> ${from}\n<b>Qayerga:</b> ${to}\n<b>Yo'lovchilar soni:</b> ${peopleNumber}\n<b>Mijoz:</b> @${WebApp.initDataUnsafe.user.username}\n`;
    if (phone.length > 5) {
      message += `<b>Telefon raqam:</b> ${phone}`;
    }

    axios.post(url, {
      chat_id: -4592051798,
      parse_mode: "html",
      text: message,
    });
  };

  const onBackButtonPressed = () => {};

  const handleChangeFrom = (event: SelectChangeEvent<typeof from>) => {
    const {
      target: { value },
    } = event;
    setFrom(value);
  };

  const handleChangeTo = (event: SelectChangeEvent<typeof from>) => {
    const {
      target: { value },
    } = event;
    setTo(value);
  };

  return (
    <WebAppProvider
      options={{
        smoothButtonsTransition: true,
      }}
    >
      <div
        className="App"
        style={{
          backgroundColor: "#22272B",
          height: "100%",
          minHeight: "100vh",
          paddingBottom: 5,
        }}
      >
        <Container maxWidth="md">
          <Grid2 container direction="column">
            <Grid2>
              <Box sx={{ padding: "30x", mt: "30px", mb: "20px" }}>
                <svg
                  width="292"
                  height="100"
                  viewBox="0 0 292 183"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M122.028 171.621C133.226 171.621 142.303 159.159 142.303 143.786C142.303 128.413 133.226 115.951 122.028 115.951C110.831 115.951 101.753 128.413 101.753 143.786C101.753 159.159 110.831 171.621 122.028 171.621Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M141.69 143.707C141.69 156.045 136.975 166.057 131.301 166.057C125.628 166.057 120.897 156.045 120.897 143.707C120.897 131.37 125.612 121.342 131.301 121.342C136.991 121.342 141.69 131.354 141.69 143.707Z"
                    fill="#D6E3DD"
                  />
                  <path
                    d="M287.497 134.702C287.497 157.664 280.425 176.273 271.686 176.273C262.947 176.273 255.859 157.664 255.859 134.702C255.859 111.739 262.947 93.1458 271.686 93.1458C280.425 93.1458 287.497 111.755 287.497 134.702Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M284.542 135.597C284.542 150.953 281.745 163.401 278.256 163.401C274.766 163.401 271.969 150.953 271.969 135.597C271.969 120.242 274.751 107.778 278.256 107.778C281.76 107.778 284.542 120.242 284.542 135.597Z"
                    fill="#D6E3DD"
                  />
                  <path
                    d="M284.149 136.493C284.149 146.788 282.279 155.149 279.969 155.149C277.658 155.149 275.788 146.788 275.788 136.493C275.788 126.199 277.658 117.853 279.969 117.853C282.279 117.853 284.149 126.214 284.149 136.493Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M282.562 137.814C282.562 143.44 281.541 148.014 280.283 148.014C279.026 148.014 278.004 143.44 278.004 137.814C278.004 132.187 279.026 127.613 280.283 127.613C281.541 127.613 282.562 132.187 282.562 137.814Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M284.071 133.57C284.071 140.36 282.091 145.861 279.67 145.861C277.25 145.861 275.269 140.36 275.269 133.57C275.269 126.78 277.234 121.264 279.67 121.264C282.106 121.264 284.071 126.78 284.071 133.57Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M29.5964 183C45.5334 183 58.4529 165.267 58.4529 143.393C58.4529 121.519 45.5334 103.786 29.5964 103.786C13.6594 103.786 0.739929 121.519 0.739929 143.393C0.739929 165.267 13.6594 183 29.5964 183Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M53.8636 145.043C53.8636 163.574 46.0837 178.599 36.5749 178.599C27.0661 178.599 19.2075 163.574 19.2075 145.043C19.2075 126.513 26.9875 111.503 36.5749 111.503C46.1622 111.503 53.8636 126.529 53.8636 145.043Z"
                    fill="#D6E3DD"
                  />
                  <path
                    d="M49.7615 147.055C49.7615 159.629 45.235 169.782 39.6712 169.782C34.1074 169.782 29.5966 159.613 29.5966 147.055C29.5966 134.497 34.1074 124.328 39.6712 124.328C45.235 124.328 49.7615 134.497 49.7615 147.055Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M217.038 182.466C231.56 182.466 243.332 164.733 243.332 142.859C243.332 120.984 231.56 103.252 217.038 103.252C202.516 103.252 190.743 120.984 190.743 142.859C190.743 164.733 202.516 182.466 217.038 182.466Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M239.309 142.67C239.309 158.214 233.603 170.819 226.562 170.819C219.521 170.819 213.816 158.246 213.816 142.67C213.816 127.095 219.521 114.489 226.562 114.489C233.603 114.489 239.309 127.063 239.309 142.67Z"
                    fill="#D6E3DD"
                  />
                  <path
                    d="M237.674 142.607C237.674 154.992 233.132 165.02 227.521 165.02C221.91 165.02 217.368 154.992 217.368 142.607C217.368 130.222 221.926 120.195 227.521 120.195C233.116 120.195 237.674 130.207 237.674 142.607Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M233.839 144.446L238.664 145.924L238.539 138.379L234.609 137.735L237.941 133.397L236.48 126.466L233.069 131.542C232.566 129.665 231.58 127.953 230.209 126.576L229.548 116.376L223.466 116.501L225.273 126.827C223.865 128.426 222.886 130.357 222.429 132.438L216.896 126.592L215.435 134.371L221.517 139.558V139.637C221.517 140.077 221.517 140.517 221.517 140.941L215.23 140.454V146.898L222.334 146.411C222.334 146.552 222.413 146.678 222.444 146.804L215.717 152.619L218.154 158.811L224.44 151.377C224.706 151.76 225.006 152.118 225.336 152.446L223.387 165.916L230.743 166.198L230.523 152.462C230.997 151.992 231.409 151.464 231.749 150.89L236.668 156.784L238.366 149.114L233.651 145.656C233.698 145.248 233.776 144.839 233.839 144.446Z"
                    fill="#D6E3DD"
                  />
                  <path
                    d="M282.232 142.026L284.212 143.425L284.432 134.623L282.515 136.195L284.26 128.698L283.536 120.32L281.839 126.434C281.493 123.763 280.629 110.01 280.629 110.01L277.485 110.199C277.485 110.199 276.951 124.548 276.621 127.487L273.886 120.431L273.163 129.861C273.163 129.861 277.108 137.939 277.124 138.474L273.038 137.216V145.075L276.558 144.478C277.375 143.472 273.808 152.069 273.808 152.069L275.631 158.701L278.869 146.851C279.01 147.338 277.611 160.823 277.611 160.823L280.755 160.085L281.085 147.511C281.305 146.992 282.421 155.369 282.421 155.369L283.882 147.401C283.882 147.401 282.138 142.513 282.232 142.026Z"
                    fill="#D6E3DD"
                  />
                  <path
                    d="M287.419 139.228C287.419 139.228 289.54 98.2538 283.804 102.419C283.804 102.419 276.668 109.099 277.061 127.77C277.061 127.77 277.47 150.136 268.165 151.912L242.106 154.521C244.59 142.528 243.158 130.055 238.02 118.937C238.02 118.937 237.36 86.1831 227.867 99.4483C227.18 100.625 226.626 101.874 226.216 103.173C220.67 117.063 218.053 131.949 218.531 146.898V147.071C218.437 147.48 218.138 148.643 217.651 149.979C216.808 152.62 215.422 155.057 213.58 157.13C213.58 157.13 199.828 157.617 179.254 158.151C132.103 159.362 49.1013 160.76 13.5651 156.815C10.1702 156.454 7.27829 156.014 4.76357 155.542C2.93627 152.52 1.52972 149.263 0.582831 145.861C0.10438 144.208 -0.0817437 142.485 0.0327729 140.768C0.0327729 140.768 0.158502 104.729 9.25866 90.0809C9.25866 90.0809 8.45708 68.6901 28.2605 62.7647C28.2605 62.7647 32.7242 58.5211 39.2625 52.4544C55.4981 37.4918 84.6375 11.3858 91.9459 9.68836C91.9459 9.68836 150.099 -7.3332 218.264 3.7473C218.264 3.7473 239.387 1.90841 256.55 24.3523C259.297 28.1117 261.711 32.1032 263.764 36.2816C266.772 42.0943 268.79 48.3674 269.737 54.8434L269.878 54.9848C270.963 56.1479 278.444 64.3207 279.764 67.3856C281.336 70.9376 285.045 89.0122 286.774 96.8078C286.774 96.8707 296.377 127.157 287.419 139.228Z"
                    fill="#EEBB2D"
                  />
                  <path
                    d="M269.564 54.9848C267.537 55.9278 248.393 64.6508 229.926 66.8983H229.831C229.344 67.1498 213.91 68.6743 204.873 62.3718C204.834 62.3479 204.802 62.3144 204.779 62.2745C204.757 62.2346 204.745 62.1897 204.745 62.1439C204.745 62.0982 204.757 62.0532 204.779 62.0133C204.802 61.9734 204.834 61.9399 204.873 61.916C206.649 60.8315 214.68 55.6292 213.816 51.0084C213.801 50.9447 213.765 50.888 213.714 50.8479C213.662 50.8078 213.598 50.7867 213.533 50.7884L39.9383 52.4701C39.8856 52.4699 39.834 52.4542 39.7902 52.4248C39.7464 52.3955 39.7122 52.3538 39.6921 52.305C39.6719 52.2563 39.6666 52.2027 39.6769 52.1509C39.6871 52.0992 39.7125 52.0517 39.7497 52.0143C56.064 37.0045 84.6847 11.4015 91.9303 9.73551C91.9303 9.73551 150.083 -7.30176 218.217 3.77874C218.217 3.77874 239.529 1.92413 256.535 24.3838C256.535 24.3838 267.662 38.9063 269.706 54.6705C269.723 54.7311 269.718 54.796 269.692 54.8535C269.666 54.911 269.621 54.9575 269.564 54.9848Z"
                    fill="#E59C0A"
                  />
                  <path
                    d="M38.9009 52.5015L34.013 56.8866L30.8696 55.8178L31.6711 51.6057C37.9579 41.2324 47.231 44.7373 47.231 44.7373L38.9009 52.5015Z"
                    fill="#111111"
                  />
                  <path
                    d="M38.6181 52.6744L33.903 56.8866C33.903 56.8866 30.7596 56.6508 30.7596 55.8178C30.6968 54.3792 30.9769 52.9462 31.5768 51.6371C33.0699 50.2854 38.6181 52.6744 38.6181 52.6744Z"
                    fill="black"
                  />
                  <path
                    d="M243.615 13.2561C243.615 13.2561 222.822 -2.11516 223.183 14.8278C223.183 14.8278 226.845 59.0869 234.106 66.2225C234.106 66.2225 264.912 60.1714 269.438 55.2205C271.089 53.4288 256.88 20.8474 243.615 13.2561Z"
                    fill="#434443"
                  />
                  <path
                    d="M226.971 27.3857C226.971 27.3857 224.598 13.2404 229.941 11.9359C229.941 11.9359 234.185 11.1186 234.892 12.1402C243.069 23.9279 248.936 37.1598 252.181 51.1342C252.181 51.1342 240.896 58.2697 235.945 54.4976C230.994 50.7255 226.971 27.3857 226.971 27.3857Z"
                    fill="#D6E3DD"
                  />
                  <path
                    d="M240.833 15.1736C246.501 18.7184 251.404 23.3575 255.258 28.8195C259.112 34.2815 261.838 40.457 263.277 46.9849C263.277 46.9849 254.57 50.2854 253.643 49.4839C252.715 48.6823 246.256 25.9083 240.173 17.154C240.173 17.154 238.586 14.7807 240.833 15.1736Z"
                    fill="#D6E3DD"
                  />
                  <path
                    d="M93.1562 12.2031C98.3743 11.1814 172.952 -3.02674 216.126 12.2031C216.772 12.4363 217.33 12.8636 217.724 13.4262C218.118 13.9889 218.329 14.6596 218.327 15.3465C218.515 23.9594 218.657 53.7746 211.537 51.0713C211.537 51.0713 87.0423 50.4583 52.6377 51.6371C51.9664 51.6574 51.3044 51.4763 50.737 51.117C50.1696 50.7578 49.7226 50.2369 49.4538 49.6215C49.185 49.0061 49.1066 48.3243 49.2286 47.6639C49.3507 47.0035 49.6676 46.3948 50.1387 45.9161C60.009 35.9515 79.671 17.2482 92.5746 12.486L93.1562 12.2031Z"
                    fill="#434443"
                  />
                  <path
                    d="M63.3095 46.4977C62.7293 46.5082 62.1594 46.343 61.6748 46.0238C61.1902 45.7046 60.8135 45.2462 60.5942 44.709C60.3749 44.1717 60.3232 43.5807 60.4459 43.0135C60.5687 42.4463 60.8602 41.9296 61.282 41.5311C71.891 31.5036 85.2662 20.1716 94.1463 16.8553C117.381 12.5377 140.944 10.224 164.574 9.93983C183.592 9.93983 199.388 11.8573 211.647 15.6608C212.223 15.8462 212.727 16.2088 213.085 16.6971C213.444 17.1853 213.639 17.7742 213.643 18.3799C213.769 28.9575 213.046 38.9849 211.851 44.0144C211.696 44.6374 211.336 45.1901 210.828 45.5836C210.321 45.977 209.696 46.1882 209.053 46.1833C200.786 46.1833 173.25 46.0261 143.246 46.0261C101.722 46.0261 77.5334 46.2147 63.3095 46.4977Z"
                    fill="#D6E3DD"
                  />
                  <mask
                    id="mask0_9_157"
                    maskUnits="userSpaceOnUse"
                    x="60"
                    y="9"
                    width="154"
                    height="38"
                  >
                    <path
                      d="M63.3095 46.4977C62.7293 46.5082 62.1594 46.343 61.6748 46.0238C61.1902 45.7046 60.8135 45.2462 60.5942 44.709C60.3749 44.1717 60.3232 43.5807 60.4459 43.0135C60.5687 42.4463 60.8602 41.9296 61.282 41.5311C71.891 31.5036 85.2662 20.1716 94.1463 16.8553C117.381 12.5377 140.944 10.224 164.574 9.93983C183.592 9.93983 199.388 11.8573 211.647 15.6608C212.223 15.8462 212.727 16.2088 213.085 16.6971C213.444 17.1853 213.639 17.7742 213.643 18.3799C213.769 28.9575 213.046 38.9849 211.851 44.0144C211.696 44.6374 211.336 45.1901 210.828 45.5836C210.321 45.977 209.696 46.1882 209.053 46.1833C200.786 46.1833 173.25 46.0261 143.246 46.0261C101.722 46.0261 77.5334 46.2147 63.3095 46.4977Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_9_157)">
                    <g opacity="0.47">
                      <path
                        d="M178.109 -0.222143L172.006 -5.58727L110.863 63.962L116.965 69.3271L178.109 -0.222143Z"
                        fill="white"
                      />
                      <path
                        d="M206.938 9.36569L191.061 -4.5919L129.918 64.9574L145.794 78.915L206.938 9.36569Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <mask
                    id="mask1_9_157"
                    maskUnits="userSpaceOnUse"
                    x="226"
                    y="11"
                    width="27"
                    height="45"
                  >
                    <path
                      d="M226.971 27.3857C226.971 27.3857 224.598 13.2404 229.941 11.9359C229.941 11.9359 234.185 11.1186 234.892 12.1402C243.069 23.9279 248.936 37.1598 252.181 51.1342C252.181 51.1342 240.896 58.2697 235.945 54.4976C230.994 50.7255 226.971 27.3857 226.971 27.3857Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask1_9_157)">
                    <g opacity="0.44">
                      <path
                        d="M242.535 11.9482L235.216 9.00148L217.283 53.5426L224.602 56.4893L242.535 11.9482Z"
                        fill="white"
                      />
                      <path
                        d="M251.624 11.439L247.542 9.79543L229.609 54.3366L233.692 55.9801L251.624 11.439Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <mask
                    id="mask2_9_157"
                    maskUnits="userSpaceOnUse"
                    x="239"
                    y="15"
                    width="25"
                    height="35"
                  >
                    <path
                      d="M240.833 15.1736C246.501 18.7184 251.404 23.3575 255.258 28.8195C259.112 34.2815 261.838 40.457 263.277 46.9848C263.277 46.9848 254.57 50.2854 253.643 49.4838C252.715 48.6823 246.256 25.9083 240.173 17.1539C240.173 17.1539 238.586 14.7807 240.833 15.1736Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask2_9_157)">
                    <path
                      opacity="0.47"
                      d="M256.167 17.9644L252.653 16.6814L243.381 42.0747L246.895 43.3577L256.167 17.9644Z"
                      fill="white"
                    />
                    <path
                      opacity="0.47"
                      d="M264.991 22.1205L257.417 19.7497L248.417 48.5036L255.991 50.8745L264.991 22.1205Z"
                      fill="white"
                    />
                  </g>
                  <path
                    d="M230.193 57.2795L231.859 61.9946C231.859 61.9946 258.986 65.138 266.043 56.4465C266.043 56.4465 267.898 36.7845 238.586 43.3856C235.442 44.1086 238.46 56.588 238.46 56.588C238.46 56.588 233.101 58.2068 230.193 57.2795Z"
                    fill="#111111"
                  />
                  <path
                    d="M282.232 104.336C282.232 104.336 285.124 95.0161 287.309 108.454C289.493 121.892 287.796 135.645 287.073 135.645C286.35 135.645 287.419 120.902 282.232 104.336Z"
                    fill="#111111"
                  />
                  <path
                    d="M224.708 105.436C224.708 105.436 231.356 107.102 238.02 118.937C238.02 118.937 239.136 95.9119 232.535 95.9119C232.535 95.9119 227.395 95.629 224.708 105.436Z"
                    fill="#111111"
                  />
                  <path
                    d="M287.246 139.307C287.246 139.307 289.289 100.14 283.804 102.419C283.804 102.419 280.896 104.462 278.051 114.804C278.051 114.804 276.59 104.525 282.342 99.2911C282.782 98.9041 283.314 98.637 283.888 98.5156C284.461 98.3942 285.056 98.4224 285.615 98.5978C286.174 98.7731 286.678 99.0895 287.08 99.5165C287.481 99.9435 287.765 100.467 287.906 101.036C290.09 109.774 294.13 129.986 287.246 139.307Z"
                    fill="#E59C0A"
                  />
                  <path
                    d="M179.191 158.199C132.04 159.424 49.0384 160.808 13.5022 156.878L13.1564 156.14C12.5895 154.354 12.7301 152.418 13.5494 150.733C14.8539 148.124 15.9698 144.446 16.9286 143.88L17.1172 143.802C18.0916 143.597 20.0877 109.334 36.7163 105.578C36.7163 105.578 69.4391 92.5799 73.8556 94.576C78.2721 96.5721 119.011 94.8432 119.011 94.8432C119.011 94.8432 157.549 100.124 166.523 115.433C166.523 115.433 176.488 134.45 177.085 144.556L179.191 158.199Z"
                    fill="#434443"
                  />
                  <path
                    d="M202.232 107.763C202.232 107.763 213.517 137.263 202.232 144.195C202.232 144.195 184.221 152.053 183.23 130.725C182.885 123.291 191.938 118.057 202.232 107.763Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M202.531 107.37C202.531 107.37 214.602 135.77 202.232 144.195C202.232 144.195 184.221 152.053 183.23 130.725C182.885 123.291 192.236 117.633 202.531 107.37Z"
                    fill="#EF9C07"
                  />
                  <path
                    d="M202.531 107.37C202.531 107.37 214.303 135.66 203.018 142.607C203.018 142.607 185.729 151.519 184.739 130.128C184.394 122.694 192.362 120.305 202.531 107.37Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M4.65352 109.02C4.08771 113.578 0.912836 139.841 2.2645 141.916C2.2645 141.916 9.68293 147.951 14.2409 141.13C18.7988 134.309 12.9992 117.083 5.21931 108.847C5.1771 108.802 5.12323 108.77 5.06376 108.754C5.00429 108.739 4.94158 108.74 4.88274 108.758C4.8239 108.776 4.77125 108.81 4.73074 108.856C4.69024 108.903 4.6635 108.959 4.65352 109.02Z"
                    fill="#E59C0A"
                  />
                  <path
                    d="M5.03077 114.207C4.19777 121.342 2.40604 137.782 3.12903 141.334C3.16556 141.513 3.23713 141.683 3.33964 141.834C3.44214 141.985 3.57352 142.114 3.72623 142.214C5.29794 143.362 11.3804 146.929 15.294 141.13C19.4118 134.953 14.5867 121.798 7.71837 113.389C7.51748 113.178 7.26187 113.027 6.98005 112.953C6.69824 112.879 6.40131 112.884 6.12249 112.969C5.84367 113.054 5.59394 113.214 5.40115 113.433C5.20836 113.652 5.08012 113.919 5.03077 114.207Z"
                    fill="#3D4039"
                  />
                  <path
                    d="M31.1053 67.6999C31.1053 67.6999 201.902 54.9848 207.969 59.9985C207.969 59.9985 215.545 54.6233 213.784 50.8198L39.624 52.3915C39.624 52.3915 28.009 62.0889 28.1976 62.8748C28.3863 63.6606 29.6593 67.637 31.1053 67.6999Z"
                    fill="#F7D877"
                  />
                  <path
                    d="M277.061 127.755C277.061 127.755 277.47 150.136 268.165 151.912L242.106 154.536L243.332 144.43L268.558 141.428C268.558 141.428 272.314 141.02 276.872 123.401C277.055 122.629 277.313 121.876 277.642 121.153C277.27 123.334 277.075 125.542 277.061 127.755Z"
                    fill="#E59C0A"
                  />
                  <g>
                    <path
                      d="M221.549 117.727C221.549 117.727 223.702 88.1477 235.395 93.9945C237.831 95.2204 240.519 100.91 238.083 118.623C238.083 118.623 236.935 94.5603 231.324 96.3992C231.324 96.3992 228.055 95.629 221.549 117.727Z"
                      fill="#EEBB2D"
                    />
                  </g>
                  <mask
                    id="mask3_9_157"
                    maskUnits="userSpaceOnUse"
                    x="12"
                    y="94"
                    width="168"
                    height="66"
                  >
                    <path
                      d="M179.191 158.199C132.04 159.424 49.0384 160.808 13.5022 156.878L13.1564 156.14C12.5895 154.354 12.7301 152.418 13.5494 150.733C14.8539 148.124 15.9698 144.446 16.9286 143.88L17.1172 143.802C18.0916 143.597 20.0877 109.334 36.7163 105.578C36.7163 105.578 69.4391 92.5799 73.8556 94.576C78.2721 96.5721 119.011 94.8432 119.011 94.8432C119.011 94.8432 157.549 100.124 166.523 115.433C166.523 115.433 176.488 134.45 177.085 144.556L179.191 158.199Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask3_9_157)">
                    <path
                      d="M1.573 95.7705L206.46 97.7037"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 99.9355L206.46 101.869"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 104.085L206.46 106.018"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 108.25L206.46 110.183"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 112.415L206.46 114.348"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 116.564L206.46 118.497"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 120.729L206.46 122.662"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 124.894L206.46 126.827"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 129.059L206.46 130.992"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 133.208L206.46 135.142"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 137.373L206.46 139.307"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 141.538L206.46 143.472"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M1.573 145.688L206.46 147.621"
                      stroke="#4B4C4B"
                      stroke-miterlimit="10"
                    />
                  </g>
                  <path
                    d="M179.191 158.199C132.04 159.424 49.0384 160.808 13.5022 156.878L13.1564 156.14C12.5895 154.354 12.7301 152.418 13.5494 150.733C14.8539 148.124 15.9698 144.446 16.9286 143.88L17.1172 143.802L38.0994 149.931C38.0994 149.931 144.221 152.305 146.987 151.503C149.753 150.701 177.085 144.588 177.085 144.588L178.279 151.896L179.191 158.199Z"
                    fill="#E59C0A"
                  />
                  <g>
                    <path
                      d="M218.531 146.898V147.071L217.651 149.978C216.802 152.618 215.416 155.052 213.58 157.13C213.58 157.13 199.828 157.617 179.254 158.136C132.103 159.362 49.1013 160.745 13.5651 156.815C10.1703 156.438 7.27833 156.014 4.77932 155.542C2.94357 152.523 1.53152 149.265 0.582855 145.861C5.83234 148.014 36.1662 159.409 82.1071 155.81C82.1071 155.81 180.323 156.407 191.403 150.529C195.207 148.517 206.979 143.142 206.979 143.142C206.979 143.142 209.006 147.558 210.405 148.155C212.26 149.067 218.531 146.898 218.531 146.898Z"
                      fill="#EEBB2D"
                    />
                  </g>
                  <path
                    d="M122.704 129.216H63.8439C62.5952 129.216 61.3588 128.97 60.2051 128.493C59.0514 128.015 58.0032 127.314 57.1202 126.431C56.2372 125.548 55.5368 124.5 55.059 123.346C54.5811 122.193 54.3351 120.956 54.3351 119.708C54.3351 118.459 54.5811 117.222 55.059 116.069C55.5368 114.915 56.2372 113.867 57.1202 112.984C58.0032 112.101 59.0514 111.4 60.2051 110.923C61.3588 110.445 62.5952 110.199 63.8439 110.199H122.704C125.226 110.199 127.645 111.201 129.428 112.984C131.211 114.767 132.213 117.186 132.213 119.708C132.213 120.956 131.967 122.193 131.489 123.346C131.011 124.5 130.311 125.548 129.428 126.431C128.545 127.314 127.497 128.015 126.343 128.493C125.189 128.97 123.953 129.216 122.704 129.216Z"
                    fill="#BFC4C1"
                  />
                  <path
                    d="M279.764 67.4484C279.764 67.4484 269.454 85.1772 213.91 72.3207C213.443 69.9914 212.593 67.7555 211.395 65.7038C211.395 65.7038 231.827 71.9907 269.454 55.2363L269.878 55.0477C270.963 56.2107 278.444 64.3836 279.764 67.4484Z"
                    fill="#F7D877"
                  />
                  <path
                    d="M9.19581 90.1438C9.19581 90.1438 5.75377 105.185 29.5179 108.878C29.5179 108.878 63.8439 94.8903 70.3822 94.8903C70.4293 94.8903 24.237 84.8629 9.19581 90.1438Z"
                    fill="#E59C0A"
                  />
                  <path
                    d="M13.5493 151.77C14.8538 149.161 15.9698 145.483 16.9285 144.918L17.1171 144.839C18.0916 144.635 20.0876 110.372 36.7162 106.631C36.7162 106.631 69.4391 93.6173 73.8556 95.6291C78.2721 97.6408 119.011 95.8963 119.011 95.8963C119.011 95.8963 157.549 101.177 166.523 116.486C166.523 116.486 176.488 135.503 177.085 145.609L179.191 158.183H182.146L180.244 145.232C179.505 134.544 169.745 115.794 169.242 114.882C159.608 98.4267 121.054 92.9729 119.419 92.7528H119.136H118.853C118.681 92.7528 102.366 93.4601 89.4783 93.4601C77.7062 93.4601 75.4587 92.8629 75.1758 92.7685C74.3173 92.4037 73.389 92.232 72.4567 92.2656C64.5982 92.2656 39.5767 102.073 35.7575 103.582C20.5277 107.26 16.4884 130.694 14.7752 140.737C14.6338 141.507 14.4923 142.309 14.3823 142.922C13.3061 144.298 12.4669 145.843 11.899 147.495C11.5061 148.47 11.0817 149.476 10.6574 150.309C8.72419 154.191 9.74582 156.438 9.74582 156.438L13.4707 156.925C13.0807 156.12 12.8846 155.234 12.8983 154.339C12.9119 153.443 13.1349 152.564 13.5493 151.77Z"
                    fill="#2A2B2A"
                  />
                  <path
                    d="M151.781 103.598C151.781 103.598 187.411 101.491 203.521 92.5957C203.521 92.5957 166.822 75.967 119.042 94.9689L151.781 103.598Z"
                    fill="#E59C0A"
                  />
                  <path
                    d="M40.5668 78.4189C40.5668 78.4189 78.0991 79.9906 81.0539 79.0476C81.0539 79.0476 94.1305 66.584 112.347 78.859C112.347 78.859 162.437 78.4661 166.885 77.6645C166.885 77.6645 155.993 86.8118 154.421 92.6114C154.421 92.6114 125.077 91.7312 119.011 94.8903C112.944 98.0495 93.0146 118.89 72.9439 95.3933C72.9439 95.3933 49.054 90.411 44.559 90.9296C44.559 90.9139 43.223 86.3403 40.5668 78.4189Z"
                    fill="#434443"
                  />
                  <path
                    d="M43.0502 86.2774L75.0501 90.6625C75.0501 90.6625 92.7474 118.387 117.297 90.9296L157.753 86.8747L160.362 83.9356C160.362 83.9356 115.506 87.7705 113.337 88.9493C113.337 88.9493 95.1207 113.374 80.0795 89.1536L41.8557 82.191L43.0502 86.2774Z"
                    fill="#D6E3DD"
                  />
                  <path
                    d="M106.61 91.3226C106.61 95.629 101.753 99.1811 95.7808 99.1811C89.8083 99.1811 84.9518 95.6919 84.9518 91.3226C84.9518 86.9532 89.8083 83.5426 95.7808 83.5426C101.753 83.5426 106.61 87.0318 106.61 91.3226Z"
                    fill="#D6E3DD"
                  />
                  <path
                    d="M44.9049 94.6231C44.9049 94.6231 19.7577 89.3422 14.4767 91.7312C14.4767 91.7312 10.2488 91.4483 9.19578 90.1595C9.19578 90.1595 8.78714 79.1576 15.9227 70.6704C19.1489 66.8966 23.4199 64.1622 28.1977 62.8119C30.5663 66.964 33.3127 70.889 36.402 74.5368C38.0994 76.3914 39.7968 77.6802 41.1171 77.6802C41.1328 77.7274 47.4667 92.7842 44.9049 94.6231Z"
                    fill="#D6E3DD"
                  />
                  <path
                    d="M213.91 72.3207C213.91 72.3207 209.949 97.6565 196.087 91.7312C196.087 91.7312 166.397 92.1241 153.321 93.7116C153.321 93.7116 156.952 81.5466 171.521 74.1753C175.412 72.2255 179.545 70.8037 183.812 69.9474C183.812 69.9474 203.081 64.2736 204.543 62.0889C204.543 62.0889 212.48 63.6606 213.863 69.0987C214.134 70.1537 214.151 71.2582 213.91 72.3207Z"
                    fill="#D6E3DD"
                  />
                  <path
                    opacity="0.62"
                    d="M36.4019 74.5525C35.8833 74.5525 22.2566 75.4641 15.9698 70.6547C19.196 66.8809 23.4671 64.1465 28.2448 62.7962C30.5968 66.9573 33.3274 70.8927 36.4019 74.5525Z"
                    fill="white"
                  />
                  <path
                    opacity="0.57"
                    d="M213.863 69.1616L213.737 69.3502C213.737 69.3502 193.714 81.1537 171.521 74.1753C175.412 72.2255 179.545 70.8037 183.812 69.9474C183.812 69.9474 203.081 64.2736 204.543 62.0889C204.543 62.1518 212.48 63.7864 213.863 69.1616Z"
                    fill="white"
                  />
                  <path
                    d="M257.871 60.8473C257.871 60.8473 273.305 91.5269 259.851 143.802"
                    stroke="#E59C0A"
                    stroke-width="0.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <g>
                    <path
                      d="M276.495 136.729C275.615 143.173 273.477 150.874 268.165 151.912L242.106 154.536C242.106 154.536 243.851 144.148 243.128 140.124L249.949 133.303C249.949 133.303 271.953 145.185 274.499 136.446C274.939 134.922 275.631 135.236 276.495 136.729Z"
                      fill="#EEBB2D"
                    />
                  </g>
                </svg>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "48px",
                    lineHeight: "90px",
                    color: "#FFFFFF",
                    mt: "20px",
                  }}
                >
                  TaxiGram
                </Typography>
              </Box>
            </Grid2>
            <Grid2>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "15px",
                  filter: "drop-shadow(0px 10px 12.1px rgba(0, 0, 0, 0.25))",
                }}
              >
                <Grid2>
                  <Typography variant="subtitle1" sx={{ mb: "20px" }}>
                    Siz qayerdan qayerga borishingizni tanlang, <br />
                    biz taxi topamiz.
                  </Typography>
                </Grid2>
                <Grid2>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-name-label">
                      Qayerdan
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      value={from}
                      sx={{ borderRadius: "50px" }}
                      onChange={handleChangeFrom}
                      input={<OutlinedInput label="Qayerdan" />}
                    >
                      {locations.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid2>
                <Grid2>
                  <FormControl sx={{ width: "100%", mt: 2 }}>
                    <InputLabel id="demo-multiple-name-label">
                      Qayerga
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      value={to}
                      sx={{ borderRadius: "50px" }}
                      onChange={handleChangeTo}
                      input={<OutlinedInput label="Qayerga" />}
                    >
                      {locations.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid2>
                <Grid2>
                  <FormControlLabel
                    value={isPhoneVisible}
                    onChange={() => setPhoneVisible(!isPhoneVisible)}
                    control={<Switch color="primary" />}
                    label="Telefon raqam kiritish"
                    labelPlacement="start"
                    sx={{ width: "100%", mt: 2 }}
                  />

                  {isPhoneVisible && (
                    <TextField
                      label="Telefon raqam"
                      id="outlined-start-adornment"
                      sx={{
                        mt: 1,
                        width: "100%",
                        borderRadius: "50px",
                        ".MuiOutlinedInput-root": {
                          borderRadius: "50px",
                        },
                      }}
                      variant="outlined"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              +998
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  )}
                </Grid2>
                <Grid2>
                  <FormControlLabel
                    value={isPeopleNumberVisible}
                    control={<Switch color="primary" />}
                    label="Yo'lovchilar sonini kiritish"
                    labelPlacement="start"
                    onChange={() =>
                      setPeopleNumberVisible(!isPeopleNumberVisible)
                    }
                    sx={{ width: "100%", mt: 2 }}
                  />

                  {isPeopleNumberVisible && (
                    <TextField
                      label="Yo'lovchilar soni"
                      id="outlined-start-adornment"
                      value={peopleNumber}
                      onChange={(e) =>
                        setPeopleNumber(parseInt(e.target.value))
                      }
                      sx={{
                        mt: 1,
                        width: "100%",
                        borderRadius: "50px",
                        ".MuiOutlinedInput-root": {
                          borderRadius: "50px",
                        },
                      }}
                      variant="outlined"
                      type="number"
                    />
                  )}
                </Grid2>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </div>
      <MainButton text="Taxi Topish" onClick={onSubmitForm} />
      <BackButton onClick={onBackButtonPressed} />
    </WebAppProvider>
  );
}

export default App;
